import {
  Image,
  Link,
  notDesktop,
  parseResourceLink,
  styled,
  Text,
} from '@obvio/app'
import { FadeIn } from '@obvio/template'
import { Accordion, Grid, Section, Stack, useLightbox } from '@obvio/ui'
import dynamic from 'next/dynamic'

import {
  CenteredSection,
  MaxWidthContent,
  QuoteSection,
} from './CenteredSection'

import type { ImageAsset, ImageProps } from '@obvio/app'
import type { RichContentViewProps } from '@obvio/template'
import type { Dict } from '@obvio/utils'
import type { ReactElement } from 'react'

const RichTextWrap = styled.div`
  h3 {
    font-size: 1.75rem;
  }

  @media ${notDesktop} {
    h2 {
      font-size: 2rem !important;
    }
  }
`

const LazyStoreCategories = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "StoreCategories" */
    './RendererVariants/StoreCategories'
  )
  return comp.StoreCategories
})

const RichTextTemplate = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "RichTextTemplate" */
    '@obvio/template'
  )
  return comp.RichText
})

function RichText({
  className,
  ...props
}: RichContentViewProps & { className?: string }) {
  return (
    <RichTextWrap className={className}>
      <RichTextTemplate {...props} />
    </RichTextWrap>
  )
}

const LazyCTASection = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "CTASection" */
    './RendererVariants/CTASection'
  )
  return comp.CTASection
})

const LazyReadMoreMargin = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ReadMoreMargin" */
    './RendererVariants/CTASection'
  )
  return comp.ReadMoreMargin
})

const LazyHeading = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Heading" */
    './RendererVariants/Heading'
  )
  return comp.Heading
})

const LazyHeroMapSection = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "HeroMapSection" */
    './RendererVariants/HeroMapSection'
  )
  return comp.HeroMapSection
})

const LazyHeroSection = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "HeroSection" */
    './RendererVariants/HeroSection'
  )
  return comp.HeroSection
})

const LazyTabsMaxWidth = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "TabsMaxWidth" */
    './RendererVariants/Tabs'
  )
  return comp.TabsMaxWidth
})

const LazyWithInlineLine = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "WithInlineLine" */
    './RendererVariants/WithInlineLine'
  )
  return comp.WithInlineLine
})

const LazyFiles = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Files" */
    '../File'
  )
  return comp.Files
})

const LazyReadMoreLink = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ReadMoreLink" */
    '../ReadMoreLink'
  )
  return comp.ReadMoreLink
})

const LazyRows = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Rows" */
    '../Renderer'
  )
  return comp.Rows
})

const LazySectionElement = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "SectionElement" */
    '../Renderer'
  )
  return comp.SectionElement
})

const Tab = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Tab" */
    '@obvio/ui'
  )
  return comp.Tab
})

const EventsGrid = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "EventsGrid" */
    './RendererVariants/Events/EventsGrid'
  )
  return comp.EventsGrid
})

const EventsSection = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "EventsSection" */
    './RendererVariants/Events/Events'
  )
  return comp.EventsSection
})

const PackagesCarousel = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "PackagesCarousel" */
    './RendererVariants/Carousels/PackagesCarousel'
  )
  return comp.PackagesCarousel
})

const ImagesCarousel = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "ImagesCarousel" */
    './RendererVariants/Carousels/ImagesCarousel'
  )
  return comp.ImagesCarousel
})

const EventsCalendar = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "EventsCalendar" */
    './RendererVariants/Events/EventsCalendar'
  )
  return comp.EventsCalendar
})

const Packages = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Packages" */
    './RendererVariants/Packages'
  )
  return comp.Packages
})

const Stats = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Stats" */
    './RendererVariants/Stats'
  )
  return comp.Stats
})

const Peace = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Peace" */
    './RendererVariants/Peace'
  )
  return comp.Peace
})

const Rooms = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Rooms" */
    './RendererVariants/Rooms'
  )
  return comp.Rooms
})

const JuraMap = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "JuraMap" */
    './RendererVariants/JuraMap/JuraMap'
  )
  return comp.JuraMap
})

const SportMap = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "SportMap" */
    './RendererVariants/SportMap/SportMap'
  )
  return comp.SportMap
})

const RoomsDetailed = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "RoomsDetailed" */
    './RendererVariants/RoomsDetailed'
  )
  return comp.RoomsDetailed
})

const PackagesDetailed = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "PackagesDetailed" */
    './RendererVariants/PackagesDetailed'
  )
  return comp.PackagesDetailed
})

const Video = dynamic(async () => {
  const comp = await import(
    /* webpackChunkName: "Video" */
    '@obvio/ui'
  )
  return comp.Video
})

type Primitives =
  | 'FILE'
  | 'RICH_TEXT'
  | 'IMAGE'
  | 'VIMEO'
  | 'YOUTUBE'
  | 'TEXT'
  | 'LINK_SELECTOR'
  | 'TAB'
  | 'SWITCH'
  | 'SWITCH-IF'
  | 'ACCORDION_GROUP'
  | 'ACCORDION'

export type ElementControl = {
  /** Ratio mapped to `fr` value, 1 if not provided */
  ratio?: number
  /** Order of elements */
  order?: number
  /** Allow for rows or columns to switch orders */
  orderable?: boolean
  /** Trigger id, most likely switch form element  */
  displayIf?: string
}

export type Column = {
  id: string
  rows?: Row[]
  type?: Primitives
  // TODO: Type it better
  props?: Dict<any>
  control?: ElementControl
}

export type Row = {
  id: string
  rows?: Row[]
  columns?: Column[]
  type?: Primitives
  // TODO: Type it better
  props?: Dict<any>
  control?: ElementControl
}

export type LayoutsDefinitions = Record<Layouts, SectionLocalType>

type SectionControl = {
  removable?: boolean
  movable?: boolean
}

export type SectionLocalType = {
  id: string
  name: string
  component?: string
  variant?: string
  control?: SectionControl
  rows?: Row[]
  columns?: Column[]
}

export type Layouts = Primitives

export const sortByOrder = (el1: Row | Column, el2: Row | Column): number =>
  (el1.control?.order ?? Infinity) - (el2.control?.order ?? Infinity)

export const buildRatiosGrid = (
  data: { control?: { ratio?: number } }[],
  type: 'row' | 'column' = 'row',
): string => {
  return data
    .map<string>((element) => {
      const ratio = element.control?.ratio

      return `minmax(0, ${
        ratio || type === 'column' ? `${ratio ?? 1}fr` : 'auto'
      })`
    })
    .join(' ')
}

const StyledAccordion = styled(Accordion.Element)`
  border-top: 1px solid ${(theme) => theme.stroke};
  padding: ${(theme) => theme.spacing.medium} 0;
  > div {
    padding: 0;
  }
  font-weight: 300;

  p {
    margin-top: ${(theme) => theme.spacing.medium};
    color: #808080 !important;
    font-size: 0.8125rem;
    padding-bottom: ${(theme) => theme.spacing.medium};
  }

  svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
`

const AccordionContent = styled.div`
  p {
    color: #808080 !important;
    font-size: 0.8125rem;
    padding-bottom: ${(theme) => theme.spacing.medium};
  }
`

const QuoteP = styled(Text)`
  font-size: 0.8125rem;
  max-width: 39.9375rem;
  margin-left: auto !important;
`

export const MinHeightImageStyle = styled(Image)`
  position: relative;
  height: 100%;
  min-height: 30rem;
  width: 100%;
`

export const MinHeightImageSingleStyle = styled(MinHeightImageStyle)`
  min-height: 36.25rem;
`

export function MinHeightImage(imageProps: ImageProps): ReactElement {
  const lightBox = useLightbox()
  return (
    <MinHeightImageStyle
      {...imageProps}
      onClick={() => imageProps.img && lightBox?.show(imageProps.img)}
    />
  )
}

export function MinHeightImageSingle(imageProps: ImageProps): ReactElement {
  const lightBox = useLightbox()
  return (
    <MinHeightImageSingleStyle
      {...imageProps}
      onClick={() => imageProps.img && lightBox?.show(imageProps.img)}
    />
  )
}

const MobileGrid = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: minmax(0, 1fr);
  }
`

export const MaxWidthGrid = styled(MobileGrid)`
  width: 100%;
  grid-template-rows: minmax(0, 1fr);
  @media ${notDesktop} {
    grid-template-columns: minmax(0, 1fr);
  }
`

const MaxWidthStack = styled(Stack)`
  width: 100%;
`

const QuoteStack = styled(Stack)`
  > div:first-of-type {
    max-width: 46.25rem;
  }
`

/**
 * Primitive is a most basic layout element, eg text, image, etc.
 */
export const primitiveRenderers = {
  FILE: ({
    props: { defaultValue },
  }: {
    props: { defaultValue: string | string[] }
  }): ReactElement => {
    return (
      <LazyFiles
        files={typeof defaultValue === 'string' ? [defaultValue] : defaultValue}
      />
    )
  },
  ARRAY: ({ rows }: { rows: Row[] }): ReactElement => (
    <Accordion>
      {rows.sort(sortByOrder).map((row) => {
        return (
          <StyledAccordion
            key={row.id}
            title={<span>{row.props?.defaultValue}</span>}
          >
            <AccordionContent>
              {row.rows
                ?.sort(sortByOrder)
                .map((innerRow) => (
                  <LazySectionElement key={innerRow.id} element={innerRow} />
                ))}
            </AccordionContent>
          </StyledAccordion>
        )
      })}
    </Accordion>
  ),
  ACCORDION: ({ props, rows }: { props: Dict; rows: Row[] }): ReactElement => (
    <StyledAccordion title={<span>{props.defaultValue}</span>}>
      <LazyRows rows={rows} />
    </StyledAccordion>
  ),
  'SWITCH-IF': ({
    props,
    rows,
  }: {
    props: Dict
    rows: Row[]
  }): ReactElement | null => {
    const isCTA = props?.label === 'CTA'

    if (isCTA && rows.length === 2) {
      const [ctaText, link] = rows
      const href = link?.props?.defaultValue as string | undefined
      const text = ctaText?.props?.defaultValue as string | undefined

      if (href && text) {
        return <LazyReadMoreLink href={href ?? '/'}>{text}</LazyReadMoreLink>
      }
    }

    return null
  },
  SWITCH: (): ReactElement | null => {
    return null
  },
  TAB: (): ReactElement => {
    return <div>Tab</div>
  },
  LINK_SELECTOR: ({ props }: { props: Dict }): ReactElement => {
    return <Link href={props.defaultValue}>LINK</Link>
  },
  RICH_TEXT: ({ props }: { props: Dict }): ReactElement => (
    <RichText value={props.defaultValue} />
  ),
  TEXT: ({ props }: { props: Dict }): ReactElement => (
    <Text tag="h3" className="LE-text">
      {props.defaultValue}
    </Text>
  ),
  IMAGE: ({ props }: { props: { defaultValue: any } }): ReactElement => {
    const image: ImageAsset = props.defaultValue

    return (
      // <ImageWrap>
      <MinHeightImage img={image} />
      // </ImageWrap>
    )
  },
  YOUTUBE: (): ReactElement => <div>YT</div>,
  VIMEO: ({ props }: { props: Dict }): ReactElement => (
    <Video source="vimeo" id={props.defaultValue} />
  ),
}

export const getImage = (
  img: ImageAsset | ImageAsset[],
): ImageAsset | undefined => {
  if (Array.isArray(img)) {
    return img?.[0]
  }
  return img
}

const RichTextHeading = styled(RichText)`
  margin-bottom: ${(theme) => theme.spacing.large};
`

const CategoriesSection = styled(Section)`
  max-width: calc(100% - 2.5rem) !important;
  width: 88rem;
`

const StoreStaSection = styled(CenteredSection)`
  flex-direction: column;
`

const StoreCtaImg = styled(MinHeightImage)`
  min-height: 36rem;
  margin-bottom: ${(theme) => theme.spacing.large};
`

const InfoContactSection = styled(Section)`
  padding-top: ${(theme) => theme.spacing.medium} !important;
  padding-bottom: ${(theme) => theme.spacing.medium} !important;
`

const MaxWidthContentInfo = styled(MaxWidthContent)`
  text-align: left;
`

const InfoHead = styled(Text)`
  font-size: 1.5rem;
  font-weight: 300;
`

const InfoAccordion = styled(StyledAccordion)`
  text-align: left;
`
const TitleSection = styled(CenteredSection)`
  padding-bottom: 0 !important;
  text-align: center;
`

export const customRenderers: Record<
  string,
  (props: any) => ReactElement | null
> = {
  // TODO: why is it "products?"
  'package-detailed': ({ products: packages }) => {
    return (
      <Section>
        <FadeIn>
          <PackagesDetailed
            ids={packages.map(
              (v: { id: string }) => parseResourceLink(v.id).id,
            )}
          />
        </FadeIn>
      </Section>
    )
  },
  // INFO
  'info-text': ({ text }) => {
    return (
      <Section>
        <FadeIn>
          <MaxWidthContentInfo>
            <InfoHead tag="h2">{text}</InfoHead>
          </MaxWidthContentInfo>
        </FadeIn>
      </Section>
    )
  },
  'info-contact': ({ title, value }) => {
    return (
      <InfoContactSection>
        <FadeIn>
          <LazyWithInlineLine title={title} value={value} />
        </FadeIn>
      </InfoContactSection>
    )
  },
  'info-accordion': ({ accordion }) => {
    return (
      <InfoContactSection>
        <FadeIn>
          <MaxWidthContent>
            <Accordion>
              {accordion.map(
                (record: {
                  title: string
                  template: string
                  data: { text: string }
                }) => (
                  <InfoAccordion
                    key={record.title}
                    title={<span>{record.title}</span>}
                  >
                    <RichText value={record.data.text} />
                  </InfoAccordion>
                ),
              )}
            </Accordion>
          </MaxWidthContent>
        </FadeIn>
      </InfoContactSection>
    )
  },
  // CONTACT
  'hero-kontakt.hero-map': (section) => {
    const text = section.rows?.[0]?.props?.defaultValue
    return <LazyHeroMapSection title={text} />
  },
  // STORE
  'store-hero.title-image': ({ img, title }) => {
    return <LazyHeroSection img={img?.[0]} text={title} />
  },
  'store-categories': () => {
    return (
      <CategoriesSection>
        <LazyStoreCategories />
      </CategoriesSection>
    )
  },
  'store-cta': ({ text, richText, image, description, ctaText, href }) => {
    return (
      <StoreStaSection>
        <MaxWidthContent>
          <LazyHeading heading={text} />
          <RichTextHeading value={richText} />
        </MaxWidthContent>
        <StoreCtaImg img={getImage(image)} />
        <MaxWidthContent>
          <RichText value={description} />
        </MaxWidthContent>
        <MaxWidthContent>
          {ctaText && href && (
            <LazyReadMoreMargin center href={href}>
              {ctaText}
            </LazyReadMoreMargin>
          )}
        </MaxWidthContent>
      </StoreStaSection>
    )
  },
  // STANDARD
  'hero.title-image': ({ img, title, seoTitle }) => {
    return (
      <>
        <LazyHeroSection img={img?.[0]} text={title} seoTitle={seoTitle} />
        <TitleSection>
          <Text tag="h1">{seoTitle}</Text>
        </TitleSection>
      </>
    )
  },
  'hero.image': ({ img }) => {
    return <LazyHeroSection img={img?.[0]} />
  },
  'hero.youtube': ({ id, seoTitle }) => {
    return (
      <>
        <LazyHeroSection ytSrc={id} seoTitle={seoTitle} />

        <TitleSection>
          <Text tag="h1">{seoTitle}</Text>
        </TitleSection>
      </>
    )
  },
  stats: () => <Stats />,
  'text-richText-heading-richText-cta': ({
    text,
    richTextHeading,
    richText,
    textCta,
    url,
  }) => (
    <CenteredSection>
      <MaxWidthContent>
        <LazyHeading heading={text} noSection />
        <RichTextHeading value={richTextHeading} />
        <RichText value={richText} />
        {textCta && url && (
          <LazyReadMoreMargin center href={url}>
            {textCta}
          </LazyReadMoreMargin>
        )}
      </MaxWidthContent>
    </CenteredSection>
  ),
  'title-richText': ({ title, richText }) => (
    <CenteredSection>
      <MaxWidthContent>
        <LazyHeading heading={title} />
        <RichText value={richText} />
      </MaxWidthContent>
    </CenteredSection>
  ),
  richText: ({ richText }) => (
    <CenteredSection>
      <MaxWidthContent>
        <RichText value={richText} />
      </MaxWidthContent>
    </CenteredSection>
  ),
  text: ({ text }) => <LazyHeading heading={text} />,
  'richText-cta': ({ richText, text, url }) => (
    <LazyCTASection description={richText} hrefText={text} href={url} />
  ),
  cta: ({ text, url }) => (
    <CenteredSection>
      <LazyReadMoreMargin center href={url}>
        {text}
      </LazyReadMoreMargin>
    </CenteredSection>
  ),
  peace: () => <Peace />,
  events: () => <EventsSection />,
  'events-calendar': () => <EventsCalendar />,
  'events-grid': () => <EventsGrid />,
  'map.map-jura': () => <JuraMap />,
  'map.map-sport': () => <SportMap />,
  packages: () => <Packages />,
  'packages-carousel': () => <PackagesCarousel />,
  rooms: () => <Rooms />,
  'img-text-img': ({ img1, text, img2 }) => (
    <CenteredSection>
      <MaxWidthGrid
        templateColumns="minmax(0,1.5fr) minmax(0,1fr)"
        gap="medium"
      >
        <Stack kind="vertical">
          <MinHeightImage img={getImage(img1)} />
          <div>
            <RichText value={text} />
          </div>
        </Stack>
        <MinHeightImage img={getImage(img2)} />
      </MaxWidthGrid>
    </CenteredSection>
  ),
  'rooms-detailed': () => <RoomsDetailed />,
  'image-carousel': ({ imgs }) => <ImagesCarousel images={imgs} />,
  image: ({ img }) => (
    <CenteredSection>
      <MaxWidthStack kind="vertical" spacing="medium">
        <MinHeightImageSingle img={getImage(img)} />
      </MaxWidthStack>
    </CenteredSection>
  ),
  'image-1-1': ({ img1, img2 }) => (
    <CenteredSection>
      <MaxWidthStack kind="vertical" spacing="medium">
        <MaxWidthGrid
          templateColumns="minmax(0,2fr) minmax(0,1fr)"
          templateRows="max-content"
          gap="medium"
        >
          <MinHeightImage img={getImage(img1)} />
          <MinHeightImage img={getImage(img2)} />
        </MaxWidthGrid>
      </MaxWidthStack>
    </CenteredSection>
  ),
  'image-1-2': ({ img1, img2, img3 }) => (
    <CenteredSection>
      <MaxWidthStack kind="vertical" spacing="medium">
        <MinHeightImage img={getImage(img1)} />
        <MaxWidthGrid
          templateColumns="minmax(0,2fr) minmax(0,1fr)"
          templateRows="max-content"
          gap="medium"
        >
          <MinHeightImage img={getImage(img2)} />
          <MinHeightImage img={getImage(img3)} />
        </MaxWidthGrid>
      </MaxWidthStack>
    </CenteredSection>
  ),
  'image-2-2': ({ img1, img2, img3, img4 }) => (
    <CenteredSection>
      <MaxWidthStack kind="vertical" spacing="medium">
        <MaxWidthGrid
          templateColumns="minmax(0,1fr) minmax(0,2fr)"
          templateRows="max-content"
          gap="medium"
        >
          <MinHeightImage img={getImage(img1)} />
          <MinHeightImage img={getImage(img2)} />
        </MaxWidthGrid>
        <MaxWidthGrid
          templateColumns="minmax(0,2fr) minmax(0,1fr)"
          templateRows="max-content"
          gap="medium"
        >
          <MinHeightImage img={getImage(img3)} />
          <MinHeightImage img={getImage(img4)} />
        </MaxWidthGrid>
      </MaxWidthStack>
    </CenteredSection>
  ),
  'img-text-cta-img': ({ img1, text, ctaText, url, img2 }) => (
    <CenteredSection>
      <MobileGrid templateColumns="minmax(0,1.5fr) minmax(0,1fr)" gap="medium">
        <Stack kind="vertical">
          <MinHeightImage img={getImage(img1)} />
          <div>
            <RichText value={text} />
          </div>
          {ctaText && url && (
            <LazyReadMoreLink href={url}>{ctaText}</LazyReadMoreLink>
          )}
        </Stack>
        <MinHeightImage img={getImage(img2)} />
      </MobileGrid>
    </CenteredSection>
  ),
  'img-text-description-x2': ({
    img1,
    text1,
    richText1,
    img2,
    text2,
    richText2,
  }) => (
    <CenteredSection>
      <MobileGrid templateColumns="minmax(0,1fr) minmax(0,1fr)" gap="small">
        <Stack kind="vertical">
          <MinHeightImage img={img1[0]} />
          <Text tag="h3" className="LE-text">
            {text1}
          </Text>
          <div>
            <RichText value={richText1} />
          </div>
        </Stack>
        <Stack kind="vertical">
          <MinHeightImage img={img2[0]} />
          <Text tag="h3" className="LE-text">
            {text2}
          </Text>
          <div>
            <RichText value={richText2} />
          </div>
        </Stack>
      </MobileGrid>
    </CenteredSection>
  ),
  'accordion-img-text-richText/richText': ({
    mainImg,
    mainTitle,
    mainDescription,
    accordion,
  }) => (
    <CenteredSection>
      <MaxWidthGrid
        templateColumns="minmax(0,1fr) minmax(0,1.75fr)"
        gap="extraLarge"
      >
        <MinHeightImage img={getImage(mainImg)} />
        <Stack kind="vertical">
          <Text tag="h3" className="LE-text">
            {mainTitle}
          </Text>
          <div>
            <RichText value={mainDescription} />
          </div>
          <div>
            <Accordion>
              {accordion.map(
                (record: {
                  title: string
                  template: string
                  data: { text: string }
                }) => (
                  <StyledAccordion
                    key={record.title}
                    title={<span>{record.title}</span>}
                  >
                    <Text tag="p">{record.data.text}</Text>
                  </StyledAccordion>
                ),
              )}
            </Accordion>
          </div>
        </Stack>
      </MaxWidthGrid>
    </CenteredSection>
  ),
  quote: ({ text, richText }) => (
    <QuoteSection>
      <QuoteStack kind="vertical" spacing="extraLarge">
        <div>
          <RichText value={richText} />
        </div>
        <QuoteP>{text ?? ''}</QuoteP>
      </QuoteStack>
    </QuoteSection>
  ),
  'tabs-accordion-text': ({
    accordion,
    tabs,
  }: {
    accordion: {
      title: string
      data: {
        accordions: {
          title: string
          template: string
          data: {
            img: ImageAsset
            title: string
            description: string
            accordion_inner: {
              title: string
              data: { title: string }
            }[]
          }
        }[]
      }
    }[]
    tabs: {
      title: string
      data: {
        accordions: {
          title: string
          template: string
          data: {
            img: ImageAsset
            title: string
            description: string
            accordion_inner: {
              title: string
              data: { title: string }
            }[]
          }
        }[]
      }
    }[]
  }) => (
    <CenteredSection>
      <LazyTabsMaxWidth keepInactiveTabPane>
        {(tabs ?? accordion).map((tab) => (
          <Tab label={tab.title} key={tab.title}>
            <Stack kind="vertical" divider>
              {tab.data.accordions.map((record) => (
                <MaxWidthGrid
                  templateColumns="minmax(0,1fr) minmax(0,1.75fr)"
                  gap="extraLarge"
                  key={record.title}
                >
                  <MinHeightImage img={getImage(record.data.img)} />
                  <Stack kind="vertical">
                    <Text tag="h3">{record.data.title}</Text>
                    <div>
                      <RichText value={record.data.description} />
                    </div>
                    <Accordion>
                      {record.data.accordion_inner.map((inner) => (
                        <InfoAccordion
                          key={inner.title}
                          title={<Text tag="span">{inner.title}</Text>}
                        >
                          <Text>{inner.data.title}</Text>
                        </InfoAccordion>
                      ))}
                    </Accordion>
                  </Stack>
                </MaxWidthGrid>
              ))}
            </Stack>
          </Tab>
        ))}
      </LazyTabsMaxWidth>
    </CenteredSection>
  ),
  'tabs-img-text-richText-cta/img': ({
    accordion,
    tabs,
  }: {
    accordion: {
      title: string
      template: string
      data: {
        text: string
        richText: string
        url: string
        ctaText: string
        img1: ImageAsset
        img2: ImageAsset
      }
    }[]
    tabs: {
      title: string
      template: string
      data: {
        text: string
        richText: string
        url: string
        ctaText: string
        img1: ImageAsset
        img2: ImageAsset
      }
    }[]
  }) => (
    <CenteredSection>
      <LazyTabsMaxWidth keepInactiveTabPane>
        {(tabs ?? accordion).map((tab, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tab label={tab.title} key={index}>
            <MaxWidthGrid
              templateColumns="minmax(0,1.5fr) minmax(0,1fr)"
              gap="medium"
            >
              <Stack kind="vertical" spacing="medium">
                <MinHeightImage img={getImage(tab.data.img1)} />
                <Text tag="h3">{tab.data.text}</Text>
                <div>
                  <RichText value={tab.data.richText} />
                </div>
                {tab.data.url && tab.data.ctaText ? (
                  <LazyReadMoreLink href={tab.data.url}>
                    {tab.data.ctaText}
                  </LazyReadMoreLink>
                ) : null}
              </Stack>
              <MinHeightImage img={getImage(tab.data.img2)} />
            </MaxWidthGrid>
          </Tab>
        ))}
      </LazyTabsMaxWidth>
    </CenteredSection>
  ),
  'files-description': ({ files, description }) => (
    <CenteredSection>
      <MaxWidthGrid templateColumns="minmax(0,1fr) minmax(0,1fr)" gap="large">
        <LazyFiles
          files={files
            .map(({ name }: { name: string }) => name)
            .filter(Boolean)}
        />
        <div>
          <RichText value={description} />
        </div>
      </MaxWidthGrid>
    </CenteredSection>
  ),
  'file-text': ({ file, text }) => (
    <CenteredSection>
      <MaxWidthGrid templateColumns="minmax(0,1fr) minmax(0,1fr)" gap="large">
        <LazyFiles
          files={file.map(({ name }: { name: string }) => name).filter(Boolean)}
        />
        <div>
          <RichText value={text} />
        </div>
      </MaxWidthGrid>
    </CenteredSection>
  ),
  'text-file': ({ file, text }) => (
    <CenteredSection>
      <MaxWidthGrid templateColumns="minmax(0,1fr) minmax(0,1fr)" gap="large">
        <div>
          <RichText value={text} />
        </div>
        <LazyFiles
          files={file.map(({ name }: { name: string }) => name).filter(Boolean)}
        />
      </MaxWidthGrid>
    </CenteredSection>
  ),
}

// export const customRenderers: Record<
//   string,
//   (
//     section: Section,
//     options: { position: 'first' | 'last' | undefined },
//   ) => ReactElement | null
// > = {
//   // TODO: Maybe create proper variant in db
//   'comp-stats': () => {
//     return <Stats />
//   },
//   'image-carousel': (section) => {
//     const images = section.rows?.[0]?.props?.defaultValue ?? []
//     return <ImagesCarousel images={images} />
//   },
//   'comp-accordions-info': (section) => {
//     return (
//       <AccordionsInfoSection>
//         <Columns columns={section.columns ?? []} spacing="extraLarge" />
//       </AccordionsInfoSection>
//     )
//   },
//   'comp-header.header-restaurant': (section) => {
//     const text = section.rows?.[0]?.props?.defaultValue
//     const image = section.rows?.[1]?.props?.defaultValue

//     return <HeroSection img={image} type="restaurant" text={text} />
//   },
//   'comp-header.image': (section) => {
//     const image = section.rows?.[0]?.props?.defaultValue

//     return <HeroSection img={image} />
//   },
//   'comp-header.header-spa': (section) => {
//     const text = section.rows?.[0]?.props?.defaultValue
//     const image = section.rows?.[1]?.props?.defaultValue

//     return <HeroSection img={image} type="spa" text={text} />
//   },
//   'comp-header.header-text': (section) => {
//     const text = section.rows?.[0]?.props?.defaultValue
//     const image = section.rows?.[1]?.props?.defaultValue

//     return <HeroSection img={image} text={text} />
//   },
//   'comp-header.youtube': (section) => {
//     const video = section.rows?.[0]?.props?.defaultValue ?? ''
//     return <HeroSection ytSrc={video} />
//   },
//   'comp-tabs.tabs-accordion-img-title-text-desc-file': customRendererTabs,
//   'comp-tabs.tabs-img-img-cta-no-title': customRendererTabs,
//   'comp-tabs.tabs-img-img-cta': customRendererTabs,
//   'heading-base': (section): ReactElement => {
//     const heading = section.rows?.find((v) => v.type === 'TEXT')
//     const description = section.rows?.find((v) => v.type === 'RICH_TEXT')

//     return (
//       <Heading
//         heading={heading?.props?.defaultValue}
//         description={description?.props?.defaultValue}
//       />
//     )
//   },
//   'text-cta': (section) => {
//     const description = section.rows?.find((v) => v.type === 'RICH_TEXT')

//     const ctaSwitch = section.rows?.find((v) => v.type === 'SWITCH-IF')
//     const hrefText = ctaSwitch?.rows?.[0]
//     const href = ctaSwitch?.rows?.[1]

//     if (!description) {
//       return null
//     }
//     return (
// <CTASection
//   description={description.props?.defaultValue ?? ''}
//   hrefText={hrefText?.props?.defaultValue}
//   href={href?.props?.defaultValue}
// />
//     )
//   },
// text: (section) => (
//   <CenteredSection>
//     <MaxWidthContent>
//       <RichText value={section?.rows?.[0]?.props?.defaultValue ?? ''} />
//     </MaxWidthContent>
//   </CenteredSection>
// ),
// quote: (section) => (
//   <QuoteSection>
//     <QuoteStack kind="vertical">
//       <RichText value={section?.rows?.[0]?.props?.defaultValue ?? ''} />
//       <QuoteP>{section?.rows?.[1]?.props?.defaultValue ?? ''}</QuoteP>
//     </QuoteStack>
//   </QuoteSection>
// ),
//   'comp-two-columns.title/text': (section, options) => {
//     const StyledSection = styled(CenteredSection)`
//       border-bottom: ${(theme) =>
//         options.position === 'last' ? 'none' : `1px solid ${theme.stroke}`};
//     `

//     return (
//       <StyledSection>
//         <Columns columns={section?.columns ?? []} />
//       </StyledSection>
//     )
//   },
//   'comp-two-columns': (section) => (
//     <CenteredSection>
//       <div
//         css={css`
//           width: 100%;
//           a {
//             border-top: 1px solid ${(theme) => theme.stroke};
//           }
//           h3 {
//             font-size: 1.5rem;
//           }
//           > div > div {
//             padding-top: 2rem;
//             @media ${mobile} {
//               border-top: 0;
//             }
//             &:first-of-type {
//               > div {
//                 max-width: 80%;
//                 @media ${mobile} {
//                   max-width: 100%;
//                 }
//               }
//               p {
//                 font-size: 1rem;
//               }
//             }
//           }
//         `}
//       >
//         <Columns columns={section?.columns ?? []} />
//       </div>
//     </CenteredSection>
//   ),
//   'comp-two-columns.title-text/text-text': (section) => (
//     <CenteredSection>
//       <div
//         css={css`
//           width: 100%;
//           a {
//             border-top: 1px solid ${(theme) => theme.stroke};
//           }
//           h3 {
//             font-size: 1.5rem;
//             font-weight: 200;
//           }
//           > div > div {
//             border-top: 1px solid ${(theme) => theme.stroke};
//             padding-top: 3rem;

//             &:first-of-type {
//               span {
//                 display: block;
//                 margin-top: 3rem;
//                 font-size: 1.5rem;
//               }
//             }
//             &:nth-of-type(2) {
//               p {
//                 font-size: 1rem;
//               }
//               a {
//                 border-top: none;
//               }
//             }
//           }
//           p {
//             font-size: 1rem;
//           }
//         `}
//       >
//         <Columns columns={section?.columns ?? []} spacing="extraLarge" />
//       </div>
//     </CenteredSection>
//   ),
//   'comp-two-columns.title-text/text-file': (section) => (
//     <CenteredSection>
//       <div
//         css={css`
//           width: 100%;
//           a {
//             border-top: 1px solid ${(theme) => theme.stroke};
//           }
//           > div > div:first-of-type {
//             span {
//               display: block;
//               margin-top: 3rem;
//               font-size: 1.5rem;
//             }
//           }
//           > div > div:nth-of-type(2) {
//             padding-top: 6rem;
//             p {
//               font-size: 0.8125rem;
//             }
//             a {
//               border-top: none;
//             }
//           }
//           p {
//             font-size: 1rem;
//           }
//         `}
//       >
//         <Columns columns={section?.columns ?? []} spacing="extraLarge" />
//       </div>
//     </CenteredSection>
//   ),
//   'comp-rooms.comp-rooms-detailed': () => <RoomsDetailed />,
//   'comp-rooms': () => <Rooms />,
//   'comp-jura-map': () => <JuraMap />,
//   'comp-events.calendar': () => <EventsCalendar />,
//   'comp-events.grid-month': () => <EventsGrid />,
//   'comp-events': () => <EventsSection />,
//   'comp-peace': () => <Peace />,
//   'comp-packages.packages-carousel': () => <PackagesCarousel />,
//   'comp-packages': () => <Packages />,
//   'grid-info-3': (section) => {
//     const elements = section.rows?.reduce<GridInfoElement[]>((acc, row) => {
//       const { columns } = row
//       const els =
//         columns?.map(({ id, rows: elementRows }) => {
//           const image = elementRows?.[0]
//           const titleNode = elementRows?.[1]
//           const cta = elementRows?.[2]
//           return {
//             id,
//             title: titleNode?.props?.defaultValue ?? '',
//             image: image?.props?.defaultValue,
//             hrefTitle: cta?.rows?.[0].props?.defaultValue,
//             href: cta?.rows?.[1].props?.defaultValue,
//           }
//         }) ?? []
//       return [...acc, ...els]
//     }, [])
//     return <GridInfo3 elements={elements ?? []} />
//   },
// }
